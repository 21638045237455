$( function() {
    let urlReferrer = document.URL,
        refArray = urlReferrer.split('/'),
        typeArray = ['mens', 'mixed', 'bshop'],
        urlLastItem = refArray[refArray.length - 1],
        bodyId = $('body').attr('id');

    if (typeArray.indexOf(urlLastItem) > -1) {
        bodyId = 'hymn-' + urlLastItem;
    }
    $('#' + bodyId + '-link').addClass('active').parent().addClass('show');

    $('header #logo, header h1').click(function () { window.location = '/'; });

    /**
     * vTicker Default settings
     * (from http://richhollis.github.io/vticker/methods/)
     *   speed: 700,
     *   pause: 4000,
     *   showItems: 1,
     *   mousePause: true,
     *   height: 0,
     *   animate: true,
     *   margin: 0,
     *   padding: 0,
     *   startPaused: false,
     *   autoAppend: true
     */
    $('.scroller').vTicker({ showItems: 7 });

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $('[data-toggle="popover"]').popover({
        html: true,
        content: 'No data available.',
    });

    $('#filterData').keyup(function () {
        let input, filter, table, tr, td, i, txtValue;
        input = document.getElementById("filterData");
        filter = input.value.toUpperCase();
        table = document.getElementById("searchableData");
        tr = table.getElementsByTagName("tr");

        // Loop through all table rows, and hide those who don't match the search query
        for (i = 0; i < tr.length; i++) {
            td = tr[i].getElementsByTagName("td")[0];
            if (td) {
                txtValue = td.textContent || td.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = "";
                } else {
                    tr[i].style.display = "none";
                }
            }
        }
    });
});
