import '../css/global.scss';

const $ = require('jquery');
require('bootstrap');
require('fontawesome');

import './jquery.vticker';
import './custom';

import '../css/custom.scss';
